import React from "react";
import styles from "./Form.module.sass";
import Action from "./Action";
import { useMediaQuery } from "react-responsive";
import Icon from "../../../../components/Icon";
import { useTranslation } from "react-i18next";

const FutureForm = ({ market, stop, limit, setValue, isBuy }) => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className={styles.form}>
      {isTablet ? (
        <>
          <>
            <div className={styles.head}>
              <div className={styles.title}>{t("form.place_order")}</div>
              <button className={styles.close} onClick={() => setValue(false)}>
                <Icon name="close-circle" size="24" />
              </button>
            </div>
            <Action
              market={market}
              stop={stop}
              limit={limit}
              classButton={isBuy ? "button-green" : "button-red"}
              isBuy={isBuy}
            />
          </>
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <Action
              market={market}
              stop={stop}
              limit={limit}
              classButton={isBuy ? "button-green" : "button-red"}
              isBuy={isBuy}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FutureForm;
