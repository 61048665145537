import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import { userSocket } from "socket.js";
import { USER_ENDPOINTS } from "socket/utils.js";
import {
  setFutureOrders,
  setFutureOrdersPast,
  setFuturePositions,
} from "store/slices/userSlice.js";
import helper from "utils/helper.js";
import { syncImage } from "store/slices/tradingPairSlice.js";
import api from "utils/api.js";
import toast from "react-hot-toast";
import Scrollbar from "components/Scrollbar/index.jsx";
import { deci } from "utils/decimal.js";
import { POSITION_TYPE } from "utils/types.js";
import TPSLModal from "components/TPSLModal/index.jsx";
import { useTranslation } from "react-i18next";
const navigation = ["positions", "open_orders", "order_history"];

const TableFutures = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(t("futures.positions"));
  const [showModifyTPSL, setShowModifyTPSL] = useState(false);
  const [positionTPSL, setPositionTPSL] = useState({});
  const userId = useSelector((state) => state.user.userId);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const orders = useSelector((state) => state.user.future.orders);
  const ordersPast = useSelector((state) => state.user.future.pastOrders);
  const positions = useSelector((state) => state.user.future.positions);
  const pairImageMap = useSelector((state) => state.tradingPair.image);
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const precesion = useSelector((state) => state.symbolData.precesion);
  const price = useSelector((state) => state.symbolData.price);

  const dispatch = useDispatch();

  const callSyncImage = (pair) => {
    if (!pairImageMap[pair]) dispatch(syncImage(pair));
    return true;
  };

  const unrealizedPNL = (position) => {
    if (!position) return "--";
    let unclosedValue = deci(position.openAmountCoin)
      .sub(position.filledAmountCoin)
      .mul(price);
    let unclosedInvestment = deci(position.openAmountUsd).sub(
      position.filledAmountUsd
    );
    let profit = unclosedValue.sub(unclosedInvestment);
    if (position.type === POSITION_TYPE.short) {
      profit = profit.mul(-1);
    }
    return parseFloat(profit.toFixed(precesion));
  };

  const realizedPNL = (position) => {
    if (!position) return "--";
    let closedValue = deci(position.filledAmountCoin).mul(price);
    let closedInvestment = deci(position.filledAmountUsd);
    let profit = closedValue.sub(closedInvestment);
    if (position.type === POSITION_TYPE.short) {
      profit = profit.mul(-1);
    }
    return parseFloat(profit.toFixed(precesion));
  };

  const pnlPercent = (profit, position) => {
    return deci(profit).abs().div(position.openAmountUsd).mul(100).toFixed(2);
  };

  const positionValue = (position) => {
    if (!position) return "--";
    let unclosedValue = deci(position.openAmountCoin)
      .sub(position.filledAmountCoin)
      .mul(price);
    let value = deci(position.filledAmountUsd).add(unclosedValue);
    return parseFloat(value.toFixed(precesion));
  };
  const cancelOrder = async (orderId) => {
    try {
      toast.promise(
        api.cancelOrderFuture({ orderId }),
        {
          loading: t("toast_messages.cancelling_order"),
          success: (result) => {
            return t("toast_messages.order_cancelled");
          },
          error: (e) => {
            console.log(e);
            return t("toast_messages.cancel_failed");
          },
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log("error at cancel order", error);
      toast.error(error.toString());
    }
  };
  const closePosition = async (position) => {
    try {
      toast.promise(
        api.closePosition({
          positionId: position.id,
          tokenAmount: deci(position.openAmountCoin)
            .sub(position.filledAmountCoin)
            .toString(),
        }),
        {
          loading: t("toast_messages.closing_position"),
          success: (result) => {
            return t("toast_messages.position_closed");
          },
          error: (e) => {
            console.log(e);
            return t("toast_messages.failed_to_close");
          },
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    }
  };
  useEffect(() => {
    userSocket.on(USER_ENDPOINTS.perpetualPositions(userId, symbol), (data) => {
      dispatch(setFuturePositions(data));
    });
    userSocket.on(USER_ENDPOINTS.futureOrders(userId, symbol), (data) => {
      dispatch(setFutureOrders(data));
    });
    userSocket.on(USER_ENDPOINTS.futureOrdersPast(userId, symbol), (data) => {
      dispatch(setFutureOrdersPast(data));
    });
    return () => {
      if (userId) {
        userSocket.off(USER_ENDPOINTS.futureOrders(userId, symbol));
        userSocket.off(USER_ENDPOINTS.perpetualPositions(userId, symbol));
      }
    };
  }, [userId, symbol]);

  useEffect(() => {
    if (userId) {
      userSocket.emit(`subscribe-${USER_ENDPOINTS.FUTURE_ORDERS}`, symbol);
      userSocket.emit(`subscribe-${USER_ENDPOINTS.FUTURE_ORDERS_PAST}`, symbol);
      userSocket.emit(
        `subscribe-${USER_ENDPOINTS.PERPETUAL_POSITIONS}`,
        symbol
      );
    }
  }, [userId, symbol]);

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((key, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(`navigation.${key}`)}
          </button>
        ))}
      </div>
      <div
        style={{
          height: "260px",
          position: "relative",
          right: "1px",
          bottom: "1px",
        }}
      >
        {activeIndex === 0 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div>{t("table.trading_pair")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.qty")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.value")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.entry_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.mark_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.liq_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.position_margin")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.leverage")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.unrealized_pnl_percentage")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.realized_pnl")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.tp_sl")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.entry_time")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.id")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.action")}</div>
                </div>
              </div>
              {positions.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.openAmountCoin)}
                  </div>
                  <div className={styles.col}>{positionValue(x)} </div>
                  <div className={styles.col}>{x.type} </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.openPrice, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(price, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.liquidationPrice, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.userInvested, precesion)}
                  </div>
                  <div className={styles.col}>{x.leverage}</div>
                  <div
                    className={styles.col}
                    style={{
                      color: unrealizedPNL(x) >= 0 ? "#58bd7d" : "#FF6838",
                    }}
                  >
                    {unrealizedPNL(x) +
                      " (" +
                      pnlPercent(unrealizedPNL(x), x) +
                      "%)"}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      color: realizedPNL(x) >= 0 ? "#58bd7d" : "#FF6838",
                    }}
                  >
                    {realizedPNL(x) +
                      " (" +
                      pnlPercent(realizedPNL(x), x) +
                      "%)"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <div className="single-line">
                      {(x.takeProfit ? deci(x.takeProfit).toString() : "--") +
                        " / " +
                        (x.stopLoss ? deci(x.stopLoss).toString() : "--")}
                    </div>
                    <button
                      className={cn("button-red", "button-mini", "button")}
                      onClick={() => {
                        setShowModifyTPSL(true);
                        setPositionTPSL(x);
                      }}
                    >
                      {t("table.modify")}
                    </button>
                  </div>
                  <div className={styles.col}>
                    {helper.formatTime(x.createdAt)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={cn("button-red", "button-mini", "button")}
                      onClick={() => {
                        closePosition(x);
                      }}
                    >
                      {t("table.close")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
        {activeIndex === 1 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div>{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_value")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.leverage")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_type")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.limit_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.stop_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.margin")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.id")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.action")}</div>
                </div>
              </div>
              {orders.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.amountUsd)}
                  </div>
                  <div className={styles.col}>{x.leverage}</div>
                  <div className={styles.col}>{x.orderType} </div>
                  <div className={styles.col}>{x.positionType} </div>
                  <div className={styles.col}>
                    {x.limitPrice ? helper.formatValue(x.limitPrice) : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      background: x.isStopPriceReached
                        ? x.tradeType === "buy"
                          ? "#58bd7d"
                          : "#FF6838"
                        : "transparent",
                    }}
                  >
                    {x.stopPrice ? helper.formatValue(x.stopPrice) : "--"}
                  </div>

                  <div className={styles.col}>
                    {helper.formatValue(
                      deci(x.amountUsd).div(x.leverage).toNumber()
                    )}
                  </div>

                  <div className={styles.col}>
                    {helper.formatTime(x.createdAt)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={cn("button-red", "button-mini", "button")}
                      onClick={() => {
                        cancelOrder(x.id);
                      }}
                    >
                      {t("table.cancel_order")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
        {activeIndex === 2 && (
          <Scrollbar style={{}}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div>{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_value")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.leverage")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_type")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.direction")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.limit_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.stop_price")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.margin")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.id")}</div>
                </div>
                <div className={styles.col}>
                  <div>{t("table.status")}</div>
                </div>
              </div>
              {ordersPast.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.amountUsd)}
                  </div>
                  <div className={styles.col}>{x.leverage}</div>
                  <div className={styles.col}>{x.orderType} </div>
                  <div className={styles.col}>{x.positionType} </div>
                  <div className={styles.col}>
                    {x.limitPrice ? helper.formatValue(x.limitPrice) : "--"}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      background: x.isStopPriceReached
                        ? x.tradeType === "buy"
                          ? "#58bd7d"
                          : "#FF6838"
                        : "transparent",
                    }}
                  >
                    {x.stopPrice ? helper.formatValue(x.stopPrice) : "--"}
                  </div>

                  <div className={styles.col}>
                    {helper.formatValue(
                      deci(x.amountUsd).div(x.leverage).toNumber()
                    )}
                  </div>

                  <div className={styles.col}>
                    {helper.formatTime(x.createdAt)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {helper.getFutureOrdersStatusLabel(x.orderStatus)}
                  </div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
      </div>
      {!isLoggedIn && (
        <div className={cn(styles.requirelogin)}>
          {t("table.require_login")}
        </div>
      )}
      <TPSLModal
        show={showModifyTPSL}
        onHide={() => setShowModifyTPSL(false)}
        position={positionTPSL}
      />
    </div>
  );
};

export default TableFutures;
