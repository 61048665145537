import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Trades.module.sass";
import { socket } from "socket.js";
import { useDispatch, useSelector } from "react-redux";
import { setRecentTrades } from "store/symbolDataSlice.js";
import helper from "utils/helper.js";
import { useTranslation } from "react-i18next";

const RecentTrades = () => {
  const { t } = useTranslation();
  const precesion = useSelector((state) => state.symbolData.precesion);
  const dispatch = useDispatch();
  const prevSymbol = useSelector((state) => state.symbolData.prevSymbol);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const trades = useSelector((state) => state.symbolData.recentTrades);
  // const trades =

  function formatTime(milliseconds) {
    const date = new Date(milliseconds);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    socket.on(`recent-trades-${symbol}`, (data) => {
      dispatch(setRecentTrades(data));
    });
    return () => {
      socket.off(`recent-trades-${symbol}`);
    };
  }, [symbol]);

  useEffect(() => {
    socket.emit("unsubscribe-recent-trades", prevSymbol);
    socket.emit("subscribe-recent-trades", symbol);
  }, [symbol]);
  return (
    <>
      <div className={styles.tablehead}>
        <div className={cn(styles.col, styles.price)}>
          {t("activity.price")}
        </div>
        <div className={cn(styles.col, styles.amount)}>{t("activity.qty")}</div>
        <div className={cn(styles.col, styles.total)}>{t("activity.time")}</div>
      </div>
      <div className={cn(styles.trades, styles.no_scrollbar)}>
        <div className={styles.table}>
          {trades.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                {x.increase && (
                  <div className={styles.positive}>
                    {helper.formatValue(x.price, precesion)}
                  </div>
                )}
                {!x.increase && (
                  <div className={styles.negative}>
                    {helper.formatValue(x.price, precesion)}
                  </div>
                )}
              </div>
              <div className={styles.col}>
                {helper.formatValue(x.qty, precesion)}
              </div>
              <div className={styles.col}>{formatTime(x.createdAt)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RecentTrades;
