import { io } from "socket.io-client";
import { socketBaseUrl } from "utils/constants.js";
import Cookies from "js-cookie";

const URL = socketBaseUrl;

export const socket = io(URL);
console.log("sesn", Cookies.get("PHPSESSID"));
export const userSocket = io(URL + "/user", {
  transports: ["websocket"],
  upgrade: false,
  auth: {
    sessionId: Cookies.get("PHPSESSID"), // Provide the token
  },
  pingInterval: 25000,  // Optional: Configure on the client as well
  pingTimeout: 20000,   // Optional: Configure timeout on the client
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 30000, // Increase client connection timeout
  reconnectionAttempts: Infinity, // Try reconnecting indefinitely
});
