import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./OrderBook.module.sass";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "socket.js";
import { setOrderBook } from "store/symbolDataSlice.js";
import helper from "utils/helper.js";
import { useTranslation } from "react-i18next";

const OrderBook = () => {
  const { t } = useTranslation();
  const precesion = useSelector((state) => state.symbolData.precesion);
  const dispatch = useDispatch();
  const orderbookDetails = useSelector((state) => state.symbolData.orderbook);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const prevSymbol = useSelector((state) => state.symbolData.prevSymbol);
  const prevPrice = useSelector((state) => state.symbolData.prevPrice);
  const currentPrice = useSelector((state) => state.symbolData.price);

  const formatValue = (value) => {
    if (parseFloat(value) === 0.0) {
      return "--";
    } else return helper.formatValue(value, precesion);
  };

  useEffect(() => {
    // Listen for 'message' event from server
    socket.on(`orderbook-${symbol}`, (data) => {
      dispatch(setOrderBook(data));
    });
    // Clean up the effect
    return () => {
      socket.off(`orderbook-${symbol}`);
    };
  }, [symbol]);

  useEffect(() => {
    socket.emit("unsubscribe-orderbook", prevSymbol);
    socket.emit("subscribe-orderbook", symbol);
    socket.emit("unsubscribe-recent-trades", prevSymbol);
    socket.emit("subscribe-recent-trades", symbol);
  }, [symbol]);

  useEffect(() => {
    // console.log("orderbook updated");
  }, [orderbookDetails]);
  return (
    <div className={cn(styles.balance, styles.no_scrollbar)}>
      <div className={styles.top}>
        <div className={styles.price}>{t("activity.price")}</div>
        <div className={styles.amount}>{t("activity.qty")}</div>
        <div className={styles.total}>{t("activity.total")}</div>
      </div>
      <div className={styles.list}>
        {orderbookDetails.asks.map((x, index) => (
          <div className={cn(styles.item, styles.negative)} key={index}>
            <div className={styles.price}>{formatValue(x.price)}</div>
            <div className={styles.amount}>{formatValue(x.qty)}</div>
            <div className={styles.total}>
              {formatValue(parseFloat(x.qty) * parseFloat(x.price))}
            </div>
            <div
              className={styles.line}
              style={{
                width: `${(x.qty * 100) / orderbookDetails.totalAskQty}%`,
              }}
            ></div>
          </div>
        ))}
        <div
          className={cn(
            styles.statistics,
            { [styles.positive]: prevPrice <= currentPrice },
            { [styles.negative]: prevPrice > currentPrice }
          )}
        >
          <div className={styles.currency}>{formatValue(currentPrice)}</div>
          <Icon
            name={`arrow-${prevPrice > currentPrice ? "down" : "top"}`}
            size="16"
          />
        </div>
        {orderbookDetails.bids.map((x, index) => (
          <div className={cn(styles.item, styles.positive)} key={index}>
            <div className={styles.price}>{formatValue(x.price)}</div>
            <div className={styles.amount}>{formatValue(x.qty)}</div>
            <div className={styles.total}>{formatValue(x.qty)}</div>
            <div
              className={styles.line}
              style={{
                width: `${(x.qty * 100) / orderbookDetails.totalBidQty}%`,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderBook;
