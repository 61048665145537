import classNames from "classnames";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "utils/api.js";
import { deci } from "utils/decimal.js";
import helper from "utils/helper.js";

const TPSLModal = ({ onHide, show, position }) => {
  const { t } = useTranslation();
  const tradingPair = useSelector((state) => state.symbolData.symbol);
  const maxLeverage = useSelector(
    (state) => state.symbolData.pairInfo.maxLeverage
  );
  const quoteTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.quoteTokenSymbol
  );
  const markPrice = useSelector((state) => state.symbolData.price);

  const [takeProfit, setTakeProfit] = useState("");
  const [stopLoss, setStopLoss] = useState("");

  const updateTPSL = (e) => {
    try {
      e.preventDefault();
      toast.promise(
        api.updateTPSLPosition({
          takeProfit: takeProfit ?? null,
          stopLoss: stopLoss ?? null,
          positionId: position.id,
        }),
        {
          success: (result) => {
            onHide();
            return t("toast_messages.position_updated");
          },
          error: (err) => err.toString(),
          loading: t("actions.processing"),
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      toast.error(error.toString());
      console.log(error);
    }
  };

  useEffect(() => {
    if (position.takeProfit) {
      setTakeProfit(deci(position.takeProfit).toString());
    }
    if (position.stopLoss) {
      setStopLoss(deci(position.stopLoss).toString());
    }
  }, [position]);
  return (
    <div
      className={classNames("mdl_overlay")}
      onClick={onHide}
      style={{
        display: show ? "flex" : "none",
      }}
    >
      <div className={"mdl_content"} onClick={(e) => e.stopPropagation()}>
        <button className={"mdl_close"} onClick={onHide}></button>
        <div
          className="title"
          style={{
            marginBottom: "20px",
          }}
        >
          <h3>{t("navigation.positions") + " " + t("table.tp_sl")}</h3>
        </div>
        <div className="modify-tp">
          <div className="media">
            <p>{t("currency.pair")}</p>
            <span>
              <img src="src/assets/images/btc-icon.svg" alt="" /> {tradingPair}{" "}
              {maxLeverage}x
            </span>
          </div>
          <div className="media">
            <p>{t("table.id")}</p>
            <span>{position.id}</span>
          </div>
          <div className="modify-box">
            <ul>
              <li>
                <p>{t("table.entry_price") + " (" + quoteTokenSymbol + ")"}</p>
                <span>{deci(position.openPrice ?? 0).toString()}</span>
              </li>
              <li>
                <p>{t("table.mark_price") + " (" + quoteTokenSymbol + ")"}</p>
                <span>{deci(markPrice ?? 0).toString()}</span>
              </li>
            </ul>
          </div>
          <div className="modify-form">
            <form className="needs-validation" noValidate="">
              <div className="form-group">
                <label htmlFor="validationCustom01">
                  {t("actions.take_profit")}{" "}
                  <img src="src/assets/images/info-icon.svg" alt="" />
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="Input Take Profit"
                    required=""
                    value={takeProfit}
                    onChange={(evt) =>
                      helper.updateIfValid(evt.target.value, setTakeProfit)
                    }
                  />
                  <span>{quoteTokenSymbol}</span>
                </div>
                <div className="error-message">
                  <p>
                    {t("table.mark_price")}&nbsp;{t("actions.reaches")}&nbsp;
                    {t("actions.take_profit")} &nbsp;{" "}
                    {t("actions.order_to_close")}
                  </p>

                  <a href="#">
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.292893 0.292893C0.683417 -0.0976309 1.31658 -0.0976309 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0976309 13.3166 -0.0976309 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                        fill="#23262F"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="validationCustom02">
                  {t("actions.stop_loss")}{" "}
                  <img src="src/assets/images/info-icon.svg" alt="" />
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className={classNames("form-control")}
                    id="validationCustom02"
                    placeholder="Input Take Loss"
                    required=""
                    value={stopLoss}
                    onChange={(evt) =>
                      helper.updateIfValid(evt.target.value, setStopLoss)
                    }
                  />
                  <span>{quoteTokenSymbol}</span>
                </div>
                <div className="error-message">
                  <p>
                    {t("table.mark_price")}&nbsp;{t("actions.reaches")}&nbsp;
                    {t("actions.take_profit")} &nbsp;{" "}
                    {t("actions.order_to_close")}
                  </p>

                  <a href="#">
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.292893 0.292893C0.683417 -0.0976309 1.31658 -0.0976309 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0976309 13.3166 -0.0976309 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
                        fill="#23262F"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn-default"
                  onClick={updateTPSL}
                >
                  {t("actions.update_position")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TPSLModal;
