import React from "react";
import "./Toggle.sass";

const Toggle = ({ checked, onChange, round }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={`slider ${round === true ? "round" : ""}`}></span>
    </label>
  );
};

export default Toggle;
