import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  loggedIn: false,
  userId: null,
  user: {
    defaultLeverage: 2,
  },
  spotOrders: [],
  spotOrdersPast: [],
  spotTrades: [],
  future: {
    orders: [],
    pastOrders: [],
    positions: [],
  },
  options: {
    orders: [],
    pastOrders: [],
    history: [],
  },
  balance: {},
};
export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setSpotOrders: (state, action) => {
      state.spotOrders = action.payload ?? [];
    },
    setSpotOrdersPast: (state, action) => {
      state.spotOrdersPast = action.payload ?? [];
    },
    setSpotTrades: (state, action) => {
      state.spotTrades = action.payload ?? [];
    },
    setTokenBalance: (state, action) => {
      const tokenSymbol = action.payload.tokenSymbol;
      state.balance[tokenSymbol] = action.payload.data;
    },
    setDefaultLeverage: (state, action) => {
      state.user.defaultLeverage = action.payload;
    },
    setFutureOrders: (state, action) => {
      state.future.orders = action.payload ?? [];
    },
    setFutureOrdersPast: (state, action) => {
      state.future.pastOrders = action.payload ?? [];
    },
    setFuturePositions: (state, actoin) => {
      state.future.positions = actoin.payload ?? [];
    },
    setOptionsOrders: (state, action) => {
      state.options.orders = action.payload ?? [];
    },
    setOptionsHistory: (state, action) => {
      state.options.history = action.payload ?? [];
    },
    setOptionsOrdersPast: (state, action) => {
      state.options.pastOrders = action.payload ?? [];
    },
  },
});

export const {
  setLoggedIn,
  setUserId,
  setDefaultLeverage,
  setSpotOrders,
  setTokenBalance,
  setSpotTrades,
  setSpotOrdersPast,
  setFutureOrders,
  setFutureOrdersPast,
  setFuturePositions,
  setOptionsHistory,
  setOptionsOrders,
  setOptionsOrdersPast,
} = userSlice.actions;
export default userSlice.reducer;
