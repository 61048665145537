import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Icon from "../Icon";
import { socket } from "socket.js";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAvailablePairs,
  setBinanceData,
  setPairInfo,
  setPrice,
} from "store/symbolDataSlice.js";
import { ENDPOINTS } from "socket/utils.js";
import Currency from "../../screens/Spot/Currency/index.js";
import "./Main.css";
import { syncImage } from "store/slices/tradingPairSlice.js";
import { TRADING_TYPE } from "utils/types.js";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const TradingPairStatBar = ({ tradingType = TRADING_TYPE.spot }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const precesion = useSelector((state) => state.symbolData.precesion);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const prevPrice = useSelector((state) => state.symbolData.prevPrice);
  const prevSymbol = useSelector((state) => state.symbolData.prevSymbol);
  const pairInfo = useSelector((state) => state.symbolData.pairInfo);

  const binancePrice = useSelector(
    (state) => state.symbolData.binanceData.lastPrice
  );
  const useBinanceApiForStats = useSelector(
    (state) => state.symbolData.pairInfo.useBinanceApiForStats
  );

  const logoMap = useSelector((state) => state.tradingPair.image);
  const fixedComponentRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showSelectCurrency, setShowSelectCurrency] = useState(false);

  const formatValue = (value) => {
    return parseFloat(value).toFixed(precesion);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    console.log("fet", useBinanceApiForStats);
    if (!useBinanceApiForStats) return;

    let intervalID = setInterval(async () => {
      try {
        let url = `https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}`;
        let result = await fetch(url);
        result = await result.json();
        dispatch(setBinanceData(result));
      } catch (error) {
        console.log("fetch error", error);
      }
    }, 700);

    return () => {
      clearInterval(intervalID);
    };
  }, [useBinanceApiForStats, symbol]);
  useEffect(() => {
    socket.on(ENDPOINTS.pairInfo(symbol), (data) => {
      dispatch(setPairInfo(data));
      dispatch(setPrice(data.currentPrice));
    });
    socket.on(ENDPOINTS.tradingPairList(), (data) => {
      dispatch(setAvailablePairs(data));
    });
    return () => {
      socket.off(ENDPOINTS.pairInfo(symbol));
    };
  }, [symbol]);

  useEffect(() => {
    socket.emit("unsubscribe-pair-info", prevSymbol);
    socket.emit("subscribe-pair-info", symbol);
  }, [symbol]);

  const handleMouseOver = () => {
    if (!isHovered) {
      setIsHovered(true);
    }
  };

  const handleMouseOut = () => {
    if (isHovered) {
      setIsHovered(false);
    }
  };
  useEffect(() => {
    if (isHovered) {
      setIsHovered(false);
    }
  }, [symbol]);

  const updateShowCurrency = async () => {
    if (isHovered) {
      setShowSelectCurrency(isHovered);
    } else {
      setShowSelectCurrency(isHovered);
    }
  };
  useEffect(() => {
    updateShowCurrency();
  }, [isHovered]);
  const callSyncImage = (pair) => {
    if (!logoMap[pair]) dispatch(syncImage(pair));
    return true;
  };
  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    if (isHovered) {
      fixedComponentRef.current.focus();
      // document.body.style.overflowY = "scroll";
      // document.body.style.position = "fixed";
      document.body.style.width = "100%";
      socket.emit(`subscribe-${ENDPOINTS.tradingPairList()}`);
    } else {
      // document.body.style.overflowY = "auto";
      // document.body.style.position = "static";
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
      socket.emit(`unsubscribe-${ENDPOINTS.tradingPairList()}`);
    }

    return () => {
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    };
  }, [isHovered]);

  const items = [
    {
      title: t("table.max_contract_volume"),
      label: "maxContractVolume",
      icon: "chart",
    },
    {
      title: t("table.change_24h"),
      label: "change24h",
      content: "520.80 +1.25%",
      icon: "clock",
      color: "#00C076",
    },
    {
      title: t("table.high_24h"),
      label: "high24h",
      content: "520.80 +1.25%",
      icon: "arrow-top",
    },
    {
      title: t("table.low_24h"),
      label: "low24h",
      content: "520.80 +1.25%",
      icon: "arrow-bottom",
    },
    {
      title: t("table.volume_24h"),
      label: "volume24h",
      content: "75.655.26",
      icon: "chart",
    },
  ];

  return (
    <div className={styles.main}>
      <div className={styles.details}>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          className={styles.box}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              {callSyncImage(symbol)}
              <img
                className={cn("coinlogo-large")}
                src={logoMap[symbol]}
                alt=""
                srcset=""
              />
            </div>
            <div>
              <div className={styles.line}>
                <div className={cn(styles.info, "symbol_title")}>{symbol}</div>
                {tradingType === TRADING_TYPE.futures && (
                  <div className={cn("category-green", styles.category)}>
                    {pairInfo.maxLeverage}x
                  </div>
                )}
              </div>
              <div className={cn(styles.content, "symbol_title")}>{symbol}</div>
            </div>
          </div>
        </div>
        <div
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{
            position: "fixed",
            top: "55px",
            left: "32px",
            zIndex: "10",
            width: isMobile ? "350px" : "400px",
            display: isHovered ? "block" : "none",
            border: "1px solid #353945",
            borderRadius: "4px",
          }}
          id="pick-currency"
          className={cn(styles.pick_currency, styles.no_scrollbar)}
          tabIndex="-1" // Ensures the element can receive focus
          ref={fixedComponentRef}
        >
          <Currency tradingType={tradingType} />
        </div>
        <div className={styles.box}>
          <div
            className={styles.price}
            style={{
              color:
                parseFloat(prevPrice) < parseFloat(pairInfo.currentPrice)
                  ? "#00C076"
                  : "#FF6838",
            }}
          >
            {formatValue(
              useBinanceApiForStats ? binancePrice : pairInfo.currentPrice
            )}
          </div>
          {/* <div className={styles.content}>
            <Icon name="coin" size="16" /> 36,641.20
          </div> */}
        </div>
      </div>
      <div className={styles.list}>
        {items.map(
          (x, index) =>
            (x.label !== "maxContractVolume" ||
              tradingType === TRADING_TYPE.futures) && (
              <div className={styles.item} key={index}>
                <div className={styles.subtitle}>
                  <Icon name={x.icon} size="16" />
                  {x.title}
                </div>
                <div
                  className={styles.content}
                  style={{
                    color:
                      x.label === "change24h"
                        ? formatValue(pairInfo[x.label]) > 0
                          ? "#00C076"
                          : "#FF6838"
                        : x.color,
                  }}
                >
                  {formatValue(pairInfo[x.label])}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default TradingPairStatBar;
