import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  image: {},
  leverage: 10,
};
export const futuresSlice = createSlice({
  name: "futures",
  initialState: initialState,
  reducers: {
    setImage: (state, action) => {
      let pair = action.payload.pair;
      let image = action.payload.image;
      state.image[pair] = image;
    },
    setLeverage: (state, action) => {
      state.leverage = action.payload;
    },
  },
});

export const { setImage, setLeverage } = futuresSlice.actions;
export default futuresSlice.reducer;
