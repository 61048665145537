import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Futures.module.sass";
import Main from "components/TradingPairStatBar/index.js";
import OrderBook from "components/OrderBook/index.js";
import MarketActivity from "components/MarketActivity/index.js";
import { useMediaQuery } from "react-responsive";
import { TRADING_TYPE } from "utils/types.js";
import TableFutures from "./TableFutures/index.js";
import FutureFormWrapper from "./FutureFormWrapper/index.jsx";
import { switchPair } from "store/symbolDataSlice.js";
import { useDispatch, useSelector } from "react-redux";
import api from "utils/api.js";
import { setLeverage } from "store/slices/futuresSlice.js";
import { setDefaultLeverage } from "store/slices/userSlice.js";
import helper from "utils/helper.js";
import { useTranslation } from "react-i18next";
import AdvancedChart from "components/AdvancedChart/index.jsx";

const Futures = () => {
  const { t } = useTranslation();
  const navigation = ["chart", "order_books"];
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const dispatch = useDispatch();
  const { symbol } = useParams();
  const availablePairs = useSelector(
    (state) => state.symbolData.availablePairs
  );

  const [isSymbolUpdated, setSymbolUpdated] = useState(false);
  const currentPair = useSelector((state) => state.symbolData.symbol);
  const defaultSymbol = useSelector((state) => state.symbolData.defaultSymbol);

  useEffect(() => {
    if (!availablePairs || !availablePairs.length || isSymbolUpdated) return;
    //trigger update once after data is fetched
    setSymbolUpdated(true);
    if (!symbol || !availablePairs.find((item) => item.symbol === symbol)) {
      //invalid symbol use default
      //TODO : check if its necessary to call dispatch for updating symbol
      helper.updateUriSymbol(TRADING_TYPE.futures, defaultSymbol);
      return;
    }

    //update only if symbol is new one
    if (symbol !== currentPair) {
      dispatch(
        switchPair({
          tradeType: TRADING_TYPE.futures,
          symbol,
        })
      );
    }

    helper.updateUriSymbol(TRADING_TYPE.futures, symbol);
  }, [symbol, defaultSymbol, availablePairs]);

  const fetchUserDefaultLeverage = async () => {
    try {
      if (!isLoggedIn) return;
      let result = await api.fetchUserInfo();
      result = result.body;
      dispatch(setDefaultLeverage(parseInt(result.leverage)));
      dispatch(setLeverage(parseInt(result.leverage)));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserDefaultLeverage();
  }, [isLoggedIn]);
  const [activeIndex, setActiveIndex] = useState(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className={styles.exchange}>
      <Main tradingType={TRADING_TYPE.futures} />
      <div className={styles.nav}>
        {navigation.map((key, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(`navigation.${key.toLowerCase().replace(" ", "_")}`)}
          </button>
        ))}
      </div>
      {isTablet ? (
        <>
          <FutureFormWrapper />
          {activeIndex === 0 && (
            <div className={styles.box}>
              <AdvancedChart />
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <OrderBook />
            </div>
          )}
          <TableFutures />
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.wrapper}>
              <AdvancedChart />
              <MarketActivity showRecentTrades={false} />
            </div>
            <div>
              <TableFutures />
            </div>
          </div>
          <div className={styles.col}>
            <FutureFormWrapper />
          </div>
        </div>
      )}
    </div>
  );
};

export default Futures;
