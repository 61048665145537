import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Options.module.sass";
import Main from "components/TradingPairStatBar/index.js";
import OrderBook from "components/OrderBook/index.js";
import MarketActivity from "components/MarketActivity/index.js";
import { useMediaQuery } from "react-responsive";
import { TRADING_TYPE } from "utils/types.js";
import TableOptions from "./TableOptions/index.js";
import OptionsFormWrapper from "./OptionsFormWrapper/index.jsx";
import { switchPair } from "store/symbolDataSlice.js";
import { useDispatch, useSelector } from "react-redux";
import helper from "utils/helper.js";
import { useTranslation } from "react-i18next";
import AdvancedChart from "components/AdvancedChart/index.jsx";

const navigation = ["order_books"];

const Options = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { symbol } = useParams();

  const availablePairs = useSelector(
    (state) => state.symbolData.availablePairs
  );

  const [isSymbolUpdated, setSymbolUpdated] = useState(false);
  const currentPair = useSelector((state) => state.symbolData.symbol);
  const defaultSymbol = useSelector((state) => state.symbolData.defaultSymbol);

  useEffect(() => {
    if (!availablePairs || !availablePairs.length || isSymbolUpdated) return;
    //trigger update once after data is fetched
    setSymbolUpdated(true);
    if (!symbol || !availablePairs.find((item) => item.symbol === symbol)) {
      //invalid symbol use default
      //TODO : check if its necessary to call dispatch for updating symbol
      helper.updateUriSymbol(TRADING_TYPE.options, defaultSymbol);
      return;
    }

    //update only if symbol is new one
    if (symbol !== currentPair) {
      dispatch(
        switchPair({
          tradeType: TRADING_TYPE.options,
          symbol,
        })
      );
    }

    helper.updateUriSymbol(TRADING_TYPE.options, symbol);
  }, [symbol, defaultSymbol, availablePairs]);
  const [activeIndex, setActiveIndex] = useState(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className={styles.exchange}>
      <Main tradingType={TRADING_TYPE.options} />
      <div className={styles.nav}>
        {navigation.map((key, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(`navigation.${key}`)}
          </button>
        ))}
      </div>
      {isTablet ? (
        <>
          <OptionsFormWrapper />
          {activeIndex === 0 && (
            <div className={styles.box}>
              <AdvancedChart tradingType={TRADING_TYPE.options} />
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <OrderBook />
            </div>
          )}
          <TableOptions />
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.wrapper}>
              <AdvancedChart tradingType={TRADING_TYPE.options} />
              <MarketActivity showRecentTrades={false} />
            </div>
            <div>
              <TableOptions />
            </div>
          </div>
          <div className={styles.col}>
            <OptionsFormWrapper />
          </div>
        </div>
      )}
    </div>
  );
};

export default Options;
