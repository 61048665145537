import { configureStore } from "@reduxjs/toolkit";
import symbolDataReducer from "./symbolDataSlice.js";
import userReducer from "./slices/userSlice.js";
import tradingPairReducer from "./slices/tradingPairSlice.js";
import futuresReducer from "./slices/futuresSlice.js";

export default configureStore({
  reducer: {
    symbolData: symbolDataReducer,
    user: userReducer,
    tradingPair: tradingPairReducer,
    futures: futuresReducer,
  },
});
