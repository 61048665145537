const bride = {
  /**
   * Ex : /wallet
   * @param {'/${link}'} target
   */
  route: (target) => {
    window.top.postMessage(
      {
        action: "route",
        target: target,
      },
      "*"
    );
  },
  switchSymbol: (symbol) => {
    window.top.postMessage(
      {
        action: "switchSymbol",
        target: symbol,
      },
      "*"
    );
  },
  setTitle: (title) => {
    window.top.postMessage(
      {
        action: "setTitle",
        title: title,
      },
      "*"
    );
  },
};
export default bride;
