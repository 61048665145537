export const ORDER_STATUS = {
  pending: 0,
  executed: 1,
  failed: 2,
  cancelled: 3,
};

export const ORDER_TYPE = {
  limit: "limit",
  market: "market",
  stop_market: "stop_market",
};

export const SPOT_TRADE_TYPE = {
  buy: "buy",
  sell: "sell",
};

export const TRADING_TYPE = {
  spot: 0,
  futures: 1,
  options: 2,
};

export const PERPETUAL_ORDER_TYPE = {
  limit: "limit",
  market: "market",
  stop_market: "stop_market",
};
export const PERPETUAL_ORDER_STATUS = {
  pending: 0,
  executed: 1,
  failed: 2,
  cancelled: 3,
};

export const POSITION_TYPE = {
  long: "long",
  short: "short",
};

export const POSITION_STATUS = {
  open: 1,
  partiallyClosed: 2,
  partiallyLiquidated: 3, // a part of closed , later it was liquidated
  liqudated: 4, // completely liquidated
  closed: 5, // closed by user without any liquation
  takenProfit: 6,
  stoppedLoss: 7,
};

export const OPTION_TRADE_STATUS = {
  open: 1,
  executed: 2,
};

export const BID_PREDICTION = {
  long: "long",
  short: "short",
  constant: "constant",
};
