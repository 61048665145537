// src/components/CustomScrollbars.js
import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const Scrollbar = ({ children }) => {
  return (
    <Scrollbars
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            backgroundColor: "#888", // Change this to your desired scrollbar color
            borderRadius: "4px",
            width: "4px", // Thin scrollbar width
          }}
        />
      )}
      renderTrackVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            position: "absolute",
            width: "6px", // Slightly larger track to provide a better click area
            right: "2px",
            bottom: "2px",
            top: "2px",
            borderRadius: "3px",
          }}
        />
      )}
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            backgroundColor: "#888",
            borderRadius: "4px",
            height: "4px", // Thin scrollbar height
          }}
        />
      )}
      renderTrackHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            position: "absolute",
            height: "6px", // Slightly larger track to provide a better click area
            left: "2px",
            right: "2px",
            bottom: "2px",
            borderRadius: "3px",
          }}
        />
      )}
    >
      {children}
    </Scrollbars>
  );
};

export default Scrollbar;
