import styles from "./Countdown.module.sass";
import cn from "classnames";
import { useTimer } from "react-timer-hook";
const TradingCountDown = ({ expiryTimestamp }) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div className={cn(styles.wrapper)}>
      <h3>Countdown in progress..</h3>
      <div style={{ display: "flex", width: "100%" }}>
        <img
          src="/images/clock.svg"
          alt="this"
          style={{ width: "100px", height: "auto", color: "#FFD166" }}
        />
        <div style={{ textAlign: "start" }}>
          <div
            style={{
              fontSize: "16px",
              color: "#777E90",
              fontWeight: "semibold",
            }}
          >
            Scheduled on:
          </div>
          <div className={styles.countdown_wrapper} style={{ display: "flex" }}>
            <div>
              <span>{days}</span>
              <span>Days</span>
            </div>
            <span>:</span>
            <div>
              <span>{hours}</span>
              <span>Hours</span>
            </div>
            <span>:</span>
            <div>
              <span>{minutes}</span>
              <span>Min</span>
            </div>
            <span>:</span>
            <div>
              <span>{seconds}</span>
              <span>Sec</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.countdown_info_wrp}>
        <div>
          <img
            src="/images/info-icon.svg"
            alt="this"
            style={{ width: "20px", height: "auto", color: "#FFD166" }}
          />
        </div>
        <div style={{ fontSize: "12px" }}>
          Transactions will be enabled after the countdown ends. Please wait
          until then. <br />
          Thank you!
        </div>
      </div>
    </div>
  );
};

export default TradingCountDown;
