import Decimal from "decimal.js";

/**
 * Returns a string without trailing zeros after decimal point
 */
function removeTrailingZeros(str) {
  // Check if the string contains a decimal point
  if (!str.includes(".")) return str;

  // Remove trailing zeros after the decimal point
  let [integerPart, decimalPart] = str.split(".");

  // Remove trailing zeros from the decimal part
  decimalPart = decimalPart.replace(/0+$/, "");

  // Reassemble the number, only if decimal part is not empty
  return decimalPart.length > 0 ? `${integerPart}.${decimalPart}` : integerPart;
}
export const fixedOf = (value, precision = 18) => {
  let decimal = deci(value);
  if (decimal == null) {
    return 0;
  }
  window.deci = deci;
  return removeTrailingZeros(decimal.toFixed(precision));
};

export const deci = (value) => {
  try {
    return new Decimal(value);
  } catch (e) {
    console.log(e, value);
    return null;
  }
};
/**
 * Sanitize input to allow only valid integers
 * rejects float values or values others than digits
 */
export const deciInt = (value) => {
  try {
    if (value.toString().includes(".")) return null;
    let _deci = new Decimal(value);
    if (!_deci.isInteger()) {
      return null;
    }
    return parseInt(value);
  } catch (error) {
    console.log(error);
    return null;
  }
};
/**
 * @returns null if value is 0
 */
export const deciNZ = (value) => {
  try {
    let dec = new Decimal(value);
    if (dec.equals(0)) {
      return null;
    }
    return dec;
  } catch (e) {
    return null;
  }
};

export const deciVal = (value, backup) => {
  try {
    let dec = new Decimal(value);
    return dec;
  } catch (e) {
    return new Decimal(backup);
  }
};

//make more robust
export const deciValNZ = (value, backup = 1) => {
  try {
    let dec = new Decimal(value);
    if (dec.eq(0)) {
      return new Decimal(1);
    }
    return dec;
  } catch (e) {
    let dec = new Decimal(backup);
    if (dec.eq(0)) {
      return new Decimal(1);
    }
    return dec;
  }
};
