import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./MarketActivity.module.sass";
import OrderBook from "../OrderBook";
import RecentTrades from "components/RecentTrades/index.js";
import { useTranslation } from "react-i18next";

const MarketActivity = ({ showRecentTrades = true }) => {
  const { t } = useTranslation();
  const [navigation, setNavigation] = useState(["order_books"]);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (showRecentTrades) {
      setNavigation(["order_books", "recent_trades"]);
    }
  }, []);
  return (
    <div className={styles.trades}>
      <div className={styles.nav}>
        {navigation.map((key, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(`navigation.${key}`)}
          </button>
        ))}
      </div>
      {activeIndex === 0 ? <OrderBook /> : <RecentTrades />}
    </div>
  );
};

export default MarketActivity;
