import { baseUrl } from "./constants.js";
const apiHelper = {
  /*
    endpoint -> base + route 
  */
  post: async (route, body, base = baseUrl) => {
    return fetch(base + route, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  },
  parseResponse: async (res) => {
    let result = await res.json();
    if (!result || !result.success) {
      throw result.message;
    }
    return result;
  },
  //TODO : Refractor for get request requiring credentials
};
const api = {
  fetchImageTradingPair: async (pair) => {
    try {
      let res = await fetch(baseUrl + `/public/pairImage?pair=${pair}`);
      let data = await res.json();
      return data.body.image;
    } catch (error) {
      console.log("image fetch error", error);
      return "";
    }
  },
  fetchTradingPairs: async () => {
    try {
      let res = await fetch(baseUrl + "/public/tradingPairs");
      let data = await res.json();
      return data.body;
    } catch (error) {
      console.log("fetch trading pairs error", error);
      return [];
    }
  },
  cancelOrderSpot: async ({ orderId }) => {
    let res = await apiHelper.post("/spot/cancelOrder", {
      orderId,
    });
    return apiHelper.parseResponse(res);
  },
  cancelOrderFuture: async ({ orderId }) => {
    let res = await apiHelper.post("/futures/cancelOrder", {
      orderId,
    });
    return apiHelper.parseResponse(res);
  },
  placeOrderSpot: async (body) => {
    let res = await apiHelper.post("/spot/placeOrder", body);
    return apiHelper.parseResponse(res);
  },
  placeOrderFuture: async (body) => {
    let res = await apiHelper.post("/futures/placeOrder", body);
    return apiHelper.parseResponse(res);
  },
  closePosition: async (body) => {
    let res = await apiHelper.post("/futures/closePosition", body);
    return apiHelper.parseResponse(res);
  },
  placeOptionsBid : async(body) => {
    let res = await apiHelper.post("/options/placeBid", body);
    return apiHelper.parseResponse(res);
  },
  updateTPSLPosition: async (body) => {
    let res = await apiHelper.post("/futures/updateTPSL", body);
    return apiHelper.parseResponse(res);
  },
  updateUserLeverage: async (leverage) => {
    let res = await apiHelper.post("/user/updateLeverage", {
      leverage,
    });
    return apiHelper.parseResponse(res);
  },
  fetchUserInfo: async () => {
    let res = await fetch(`${baseUrl}/user/info`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return apiHelper.parseResponse(res);
  },
};
export default api;
