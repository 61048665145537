import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import helper from "utils/helper.js";
import { setAllBinanceDataMap, switchPair } from "store/symbolDataSlice.js";
import { syncImage } from "store/slices/tradingPairSlice.js";
import { TRADING_TYPE } from "utils/types.js";
import { useTranslation } from "react-i18next";

const navigation = ["all"];

const Currency = ({ tradingType }) => {
  const { t } = useTranslation();
  const pairImageMap = useSelector((state) => state.tradingPair.image);

  const [pairForTradeType, setPairsForTradeType] = useState([]);
  const availablePairs = useSelector(
    (state) => state.symbolData.availablePairs
  );
  const binanceDataMap = useSelector(
    (state) => state.symbolData.allBinanceDataMap
  );
  const precesion = useSelector((state) => state.symbolData.precesion);
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const updateCurrency = (pair) => {
    helper.updateUriSymbol(tradingType, pair);
    dispatch(
      switchPair({
        tradeType: tradingType,
        symbol: pair,
      })
    );
  };
  const formatValue = (value) => {
    return helper.formatValue(value, precesion);
  };
  const handleSubmit = (e) => {
    alert();
  };
  const callSyncImage = (pair) => {
    if (!pairImageMap[pair]) dispatch(syncImage(pair));
    return true;
  };

  useEffect(() => {
    let symbolArray = [];
    for (let i = 0; i < availablePairs.length; i++) {
      if (!availablePairs[i].useBinanceApiForStats) {
        continue;
      }
      symbolArray.push(availablePairs[i].symbol);
    }
    let fetchData = async () => {
      try {
        let result = await fetch(
          `https://api.binance.com/api/v3/ticker/24hr?symbols=${JSON.stringify(
            symbolArray
          )}`
        );
        result = await result.json();
        let dataMap = {};
        for (let i = 0; i < result.length; i++) {
          dataMap[result[i].symbol] = result[i];
        }
        console.log("fet", dataMap);
        dispatch(setAllBinanceDataMap(dataMap));
      } catch (error) {
        console.log("something went wrong", error);
      }
    };
    let intervalID = setInterval(fetchData, 700);
    fetchData();
    return () => {
      clearInterval(intervalID);
    };
  }, [availablePairs, dispatch]);
  useEffect(() => {
    let validPairs = availablePairs.filter((item) => {
      if (tradingType === TRADING_TYPE.spot) {
        return item.spotTradingEnabled;
      } else if (tradingType === TRADING_TYPE.futures) {
        return item.marginTradingEnabled;
      }
      return item.optionsTradingEnabled;
    });
    setPairsForTradeType(validPairs);
  }, [availablePairs, tradingType]);

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {navigation.map((key, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => {
              setActiveIndex(index);
            }}
            key={index}
          >
            {t(`navigation.${key}`)}
          </button>
        ))}
      </div>
      <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder={t("currency.search")}
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">{t("currency.pair")}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t("currency.price")}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t("currency.volume")}</div>
          </div>
        </div>
        {/* 
          TODO : Refractor : Use Scrollbar component 
        */}
        <div
          style={{
            maxHeight: "300px",
            overflow: "scroll",
          }}
          className={styles.no_scrollbar}
        >
          {binanceDataMap &&
            pairForTradeType.map((x, index) => (
              <div
                className={styles.row}
                key={x.symbol}
                onClick={() => updateCurrency(x.symbol)}
              >
                <div className={styles.col}>
                  <div className={styles.line}>
                    <button className={cn("favorite", styles.favorite)}>
                      <Icon name="star-outline" size="16" />
                    </button>
                    <div
                      className={styles.info}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {callSyncImage(x.symbol) && (
                        <img
                          src={pairImageMap[x.symbol]}
                          alt=""
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: "4px",
                          }}
                        />
                      )}
                      {x.symbol}
                      {tradingType === TRADING_TYPE.futures && (
                        <div
                          className={cn("category-green", styles.category)}
                          style={{
                            padding: "4px",
                            fontSize: "10px",
                            marginLeft: "4px",
                          }}
                        >
                          {x.maxLeverage}x
                        </div>
                      )}
                      {/* <span>/{x.currency}</span> */}
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <div>
                    {formatValue(
                      x.useBinanceApiForStats && binanceDataMap[x.symbol]
                        ? binanceDataMap[x.symbol].lastPrice
                        : x.currentPrice
                    )}
                  </div>
                  {/* {x.positive && (
                  <div className={styles.positive}>{x.positive}</div>
                )}
                {x.negative && (
                  <div className={styles.negative}>{x.negative}</div>
                )} */}
                </div>
                <div className={styles.col}>
                  {formatValue(
                    x.useBinanceApiForStats && binanceDataMap[x.symbol]
                      ? binanceDataMap[x.symbol].volume
                      : x.volume24h
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Currency;
