import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "utils/api.js";

let initialState = {
  image: {},
};

export const syncImage = createAsyncThunk(
  "tradingPair/syncImage",
  async (pair, { rejectWithValue }) => {
    try {
      const image = await api.fetchImageTradingPair(pair);
      return { pair, image };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const tradingPairSlice = createSlice({
  name: "tradingPair",
  initialState: initialState,
  reducers: {
    setImage: (state, action) => {
      let pair = action.payload.pair;
      let image = action.payload.image;
      state.image[pair] = image;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(syncImage.fulfilled, (state, action) => {
      const { pair, image } = action.payload;
      state.image[pair] = image;
    });
  },
});

export const { setImage } = tradingPairSlice.actions;
export default tradingPairSlice.reducer;
