import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Actions.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { userSocket } from "socket.js";
import { USER_ENDPOINTS } from "socket/utils.js";
import { setTokenBalance } from "store/slices/userSlice.js";
import OptionsForm from "./OptionsForm/index.js";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import bride from "utils/php_bridge.js";
import { useTranslation } from "react-i18next";

const OptionsFormWrapper = () => {
  const { t } = useTranslation();
  const [visibleAction, setVisibleAction] = useState(false);
  const [visible, setVisible] = useState(false);

  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const isTableOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  //Reusable copmonents
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const baseTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.baseTokenSymbol
  );
  const quoteTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.quoteTokenSymbol
  );
  const prevBaseTokenSymbol = useSelector(
    (state) => state.symbolData.prevPairData.baseTokenSymbol
  );
  const prevQuoteTokenSymbol = useSelector(
    (state) => state.symbolData.prevPairData.quoteTokenSymbol
  );
  const symbol = useSelector((state) => state.symbolData.symbol);

  useEffect(() => {
    // Listen for 'message' event from server
    userSocket.on(
      `${USER_ENDPOINTS.balance(userId, baseTokenSymbol)}`,
      (data) => {
        dispatch(
          setTokenBalance({
            tokenSymbol: baseTokenSymbol,
            data,
          })
        );
      }
    );
    userSocket.on(
      `${USER_ENDPOINTS.balance(userId, quoteTokenSymbol)}`,
      (data) => {
        dispatch(
          setTokenBalance({
            tokenSymbol: quoteTokenSymbol,
            data,
          })
        );
      }
    );
    // Clean up the effect
    return () => {
      userSocket.off(`${USER_ENDPOINTS.balance(userId, prevBaseTokenSymbol)}`);
      userSocket.off(`${USER_ENDPOINTS.balance(userId, prevQuoteTokenSymbol)}`);
    };
  }, [symbol, userId]);

  const relistenSocket = async () => {
    if (userId) {
      userSocket.emit(
        `unsubscribe-${USER_ENDPOINTS.BALANCE}`,
        prevBaseTokenSymbol
      );
      userSocket.emit(
        `unsubscribe-${USER_ENDPOINTS.BALANCE}`,
        prevQuoteTokenSymbol
      );
      userSocket.emit(`subscribe-${USER_ENDPOINTS.BALANCE}`, baseTokenSymbol);
      userSocket.emit(`subscribe-${USER_ENDPOINTS.BALANCE}`, quoteTokenSymbol);
    }
  };
  useEffect(() => {
    relistenSocket();
  }, [symbol, userId]);

  //END SYNC

  const handleLongClickMiniScreen = () => {
    setVisibleAction(true);
    setVisible(true);
  };

  return (
    <div className={styles.actions}>
      <div className={cn(styles.wrapper, { [styles.show]: visible })}>
        {<OptionsForm visible={visibleAction} setValue={setVisible} />}
      </div>

      {isLoggedIn && isTableOrMobile && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className={cn("button-green", styles.button)}
            onClick={() => {
              handleLongClickMiniScreen();
            }}
            style={{
              flexGrow: 1,
            }}
          >
            {t("actions.long")}
          </button>
          <button
            className={cn("button-red", styles.button)}
            onClick={() => {
              handleLongClickMiniScreen();
            }}
            style={{
              flexGrow: 1,
            }}
          >
            {t("actions.short")}
          </button>
        </div>
      )}
      {!isLoggedIn && isTableOrMobile && (
        <div className={styles.btns}>
          <Link
            className={cn("button", styles.button)}
            activeClassName={styles.active}
            to="/sign-up"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-up.php");
            }}
          >
            {t("actions.sign_up")}
          </Link>
          <Link
            style={{ marginTop: "8px" }}
            className={cn("button-stroke", styles.button)}
            activeClassName={styles.active}
            to="/sign-in"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-in.php");
            }}
          >
            {t("actions.login")}
          </Link>
        </div>
      )}
    </div>
  );
};

export default OptionsFormWrapper;
