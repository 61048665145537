import React, { useEffect, useState } from "react";
import cn from "classnames";
import api from "utils/api.js";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setDefaultLeverage } from "store/slices/userSlice.js";
import { setLeverage } from "store/slices/futuresSlice.js";
import { useTranslation } from "react-i18next";
const LeverageModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  const [selectedLeverage, setSelectedLeverage] = useState(0);
  const maxLeverage = useSelector(
    (state) => state.symbolData.pairInfo.maxLeverage
  );
  const leverage = useSelector((state) => state.futures.leverage);

  const dispatch = useDispatch();

  const fetchUserDefaultLeverage = async () => {
    try {
      let result = await api.fetchUserInfo();
      result = result.body;
      dispatch(setDefaultLeverage(parseInt(result.leverage)));
    } catch (error) {
      console.log(error);
    }
  };

  const updateLeverage = (_leverage) => {
    dispatch(setLeverage(_leverage));
  };
  const increaseInputCount = (e) => {
    setSelectedLeverage(
      selectedLeverage < maxLeverage ? selectedLeverage + 1 : selectedLeverage
    );
    e.preventDefault();
  };

  const decreaseInputCount = (e) => {
    setSelectedLeverage(
      selectedLeverage > 1 ? selectedLeverage - 1 : selectedLeverage
    );
    e.preventDefault();
  };

  const confirmLeverageUpdate = async () => {
    try {
      toast.promise(
        api.updateUserLeverage(selectedLeverage),
        {
          success: (result) => {
            updateLeverage(selectedLeverage);
            fetchUserDefaultLeverage();
            setTimeout(() => {
              onHide();
            }, 10);
            return t("leverage.updated");
          },
          error: (err) => err.toString(),
          loading: t("leverage.processing"),
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedLeverage(leverage);
  }, [leverage]);

  return (
    <div
      className={cn("mdl_overlay", { show: show })}
      onClick={onHide}
      style={{
        display: show ? "flex" : "none",
      }}
    >
      <div
        className={cn("mdl_content", { show: show })}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={"mdl_close"} onClick={onHide}></button>
        <div className="title">
          <h3>{t("leverage.adjust")}</h3>
          <a
            href="#"
            onClick={onHide}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></a>
        </div>
        <div className="leverage-box" style={{ marginTop: "10px" }}>
          <h4>{t("leverage.title")}</h4>
          <div className="quantity-box">
            <button
              type="button"
              onClick={decreaseInputCount}
              id="sub"
              className="sub"
              style={{
                color: "#777E90",
                scale: "1.8",
              }}
            >
              &minus;
            </button>
            <input
              className="count"
              type="text"
              id={1}
              value={selectedLeverage}
              defaultValue={1}
              min={1}
              max={100}
            />
            <button
              onClick={increaseInputCount}
              type="button"
              id="add"
              style={{
                color: "#777E90",
                scale: "1.8",
              }}
              className="add"
            >
              +
            </button>
          </div>
          <p>{t("leverage.min_position")}: 12,23,34,567 USDT</p>
          <a
            href="#"
            className={cn("button", "width-full")}
            onClick={confirmLeverageUpdate}
          >
            {t("leverage.confirm")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LeverageModal;
