export const ENDPOINTS = {
  recentTrades: (pair) => {
    return `recent-trades-${pair}`;
  },
  pairInfo: (pair) => {
    return `pair-info-${pair}`;
  },
  tradingPairList: () => {
    return `tp-list`;
  },
};

export const USER_ENDPOINTS = {
  SPOT_ORDERS: `orders-spot`,
  SPOT_ORDERS_PAST: `orders-spot-past`,
  SPOT_TRADES: `trades-spot`,
  FUTURE_ORDERS: "orders-futures",
  FUTURE_ORDERS_PAST: "orders-futures-past",
  PERPETUAL_POSITIONS: `perpetual-positions`,
  BALANCE: `balance`,
  OPTION_BIDS: `option-bids`,
  OPTION_BIDS_PAST: `option-bids-past`,
  spotOrders: (userId, symbol) => {
    return `orders-spot-${symbol}-${userId}`;
  },
  spotOrdersPast: (userId, symbol) => {
    return `orders-spot-past-${symbol}-${userId}`;
  },
  spotTrades: (userId, symbol) => {
    return `spot-trades-${symbol}-${userId}`;
  },
  futureOrders: (userId,symbol) => {
    return `orders-futures-${symbol}-${userId}`;
  },
  futureOrdersPast: (userId,symbol) => {
    return `orders-futures-past-${symbol}-${userId}`;
  },
  perpetualPositions: (userId,symbol) => {
    return `perpetual-positions-${symbol}-${userId}`;
  },
  balance: (userId, tokenSymbol) => {
    return `balance-${tokenSymbol}-${userId}`;
  },
  optionBids: (userId,symbol) => {
    return `option-bids-${symbol}-${userId}`;
  },
  optionBidsPast: (userId,symbol) => {
    return `option-bids-${symbol}-past-${userId}`;
  },
};