import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import { userSocket } from "socket.js";
import { USER_ENDPOINTS } from "socket/utils.js";
import {
  setOptionsOrders,
  setOptionsOrdersPast,
} from "store/slices/userSlice.js";
import helper from "utils/helper.js";
import { syncImage } from "store/slices/tradingPairSlice.js";
import Scrollbar from "components/Scrollbar/index.jsx";
import { useTranslation } from "react-i18next";
const navigation = ["Open Orders", "Past Orders"];

const TableOptions = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const symbol = useSelector((state) => state.symbolData.symbol);
  const userId = useSelector((state) => state.user.userId);
  const optionsOrders = useSelector((state) => state.user.options.orders);
  const pastOrders = useSelector((state) => state.user.options.pastOrders);
  const pairImageMap = useSelector((state) => state.tradingPair.image);
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const precesion = useSelector((state) => state.symbolData.precesion);

  const dispatch = useDispatch();

  useEffect(() => {
    userSocket.on(USER_ENDPOINTS.optionBids(userId, symbol), (data) => {
      dispatch(setOptionsOrders(data));
    });
    userSocket.on(USER_ENDPOINTS.optionBidsPast(userId, symbol), (data) => {
      dispatch(setOptionsOrdersPast(data));
    });
    return () => {
      userSocket.off(USER_ENDPOINTS.optionBids(userId, symbol));
      userSocket.off(USER_ENDPOINTS.optionBidsPast(userId, symbol));
    };
  }, [userId, symbol]);

  useEffect(() => {
    if (userId) {
      userSocket.emit(`subscribe-${USER_ENDPOINTS.OPTION_BIDS}`, symbol);
      userSocket.emit(`subscribe-${USER_ENDPOINTS.OPTION_BIDS_PAST}`, symbol);
    }
  }, [userId, symbol]);

  const callSyncImage = (pair) => {
    if (!pairImageMap[pair]) dispatch(syncImage(pair));
    return true;
  };

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={[t("navigation.open_orders"), t("navigation.past_orders")]}
      />
      <div className={styles.nav}>
        {[t("navigation.open_orders"), t("navigation.past_orders")].map(
          (x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          )
        )}
      </div>
      <div
        style={{
          height: "260px",
          position: "relative",
          right: "1px",
          bottom: "1px",
        }}
      >
        {activeIndex === 0 && (
          <Scrollbar>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className="">{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.prediction")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.countdown")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.open_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.bid_amount")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.return_rate")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.pick_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_id")}</div>
                </div>
              </div>
              {optionsOrders.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>{x.pick} </div>
                  <div className={styles.col}>
                    {helper.getSecondsGap(new Date(), x.winPickTime)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.openPrice, precesion)}
                  </div>

                  <div className={styles.col}>
                    {helper.formatValue(x.amountUsd, precesion)}
                  </div>
                  <div className={styles.col}>{x.optionsReturnRate}</div>

                  <div className={styles.col}>
                    {helper.formatTime(x.bidTime)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatTime(x.winPickTime)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
        {activeIndex === 1 && (
          <Scrollbar>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className="">{t("table.spot_pairs")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.prediction")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.result")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.open_price")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.bid_amount")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.return_rate")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.settled_amount")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.fee")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.pick_time")}</div>
                </div>
                <div className={styles.col}>
                  <div className="">{t("table.order_id")}</div>
                </div>
              </div>
              {pastOrders.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    {pairImageMap && callSyncImage(x.tradingPair) && (
                      <img
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: "6px",
                        }}
                        src={pairImageMap[x.tradingPair] ?? ""}
                        alt=""
                      />
                    )}
                    {x.tradingPair}
                  </div>
                  <div className={styles.col}>{x.pick} </div>
                  <div
                    className={styles.col}
                    style={{
                      background: x.pick === x.result ? "#58bd7d" : "#FF6838",
                    }}
                  >
                    {x.result}{" "}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.openPrice, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.amountUsd, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.optionsReturnRate, precesion)}
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      color: x.pick === x.result ? "#58bd7d" : "#FF6838",
                    }}
                  >
                    {helper.formatValue(x.settledAmount, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatValue(x.feeUsd, precesion)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatTime(x.bidTime)}
                  </div>
                  <div className={styles.col}>
                    {helper.formatTime(x.winPickTime)}
                  </div>
                  <div className={styles.col}>{x.id}</div>
                </div>
              ))}
            </div>
          </Scrollbar>
        )}
      </div>

      {!isLoggedIn && (
        <div className={cn(styles.requirelogin)}>
          {t("table.require_login")}
        </div>
      )}
    </div>
  );
};

export default TableOptions;
