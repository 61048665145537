import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "../../components/TradingPairStatBar";
import Orderbook from "../../components/OrderBook";
import RecentTrades from "components/RecentTrades/index.js";
import Table from "./TableSpot";
import SpotFormWrapper from "./SpotFormWrapper";
import { useMediaQuery } from "react-responsive";
import MarketActivity from "../../components/MarketActivity";
import { useDispatch, useSelector } from "react-redux";
import { switchPair } from "store/symbolDataSlice.js";
import { TRADING_TYPE } from "utils/types.js";
import helper from "utils/helper.js";
import { useTranslation } from "react-i18next";
import AdvancedChart from "components/AdvancedChart/index.jsx";
import TradingCountDown from "components/TradingCountDown/index.jsx";
const Spot = () => {
  const { t } = useTranslation();
  const navigation = [
    t("navigation.chart"),
    t("navigation.order_books"),
    t("navigation.trades"),
  ];
  const { symbol } = useParams();
  const dispatch = useDispatch();
  const availablePairs = useSelector(
    (state) => state.symbolData.availablePairs
  );

  const [isSymbolUpdated, setSymbolUpdated] = useState(false);

  const currentPair = useSelector((state) => state.symbolData.symbol);
  const defaultSymbol = useSelector((state) => state.symbolData.defaultSymbol);
  const pairInfo = useSelector((state) => state.symbolData.pairInfo);

  const [openDate, setOpenDate] = useState(new Date(Date.now() - 7 * 86400000));
  const [closeDate, setCloseDate] = useState(
    new Date(Date.now() + 7 * 86400000)
  );

  useEffect(() => {
    if (pairInfo.openDate) {
      setOpenDate(new Date(pairInfo.openDate));
    } else {
      setOpenDate(new Date(Date.now() - 7 * 86400000));
    }

    if (pairInfo.closeDate) {
      setCloseDate(new Date(pairInfo.closeDate));
    } else {
      setCloseDate(new Date(Date.now() + 7 * 86400000));
    }
  }, [pairInfo]);
  useEffect(() => {
    if (!availablePairs || !availablePairs.length || isSymbolUpdated) return;
    //trigger update once after data is fetched
    setSymbolUpdated(true);
    if (!symbol || !availablePairs.find((item) => item.symbol === symbol)) {
      //invalid symbol use default
      //TODO : check if its necessary to call dispatch for updating symbol
      helper.updateUriSymbol(TRADING_TYPE.spot, defaultSymbol);
      return;
    }

    //update only if symbol is new one
    if (symbol !== currentPair) {
      dispatch(
        switchPair({
          tradeType: TRADING_TYPE.spot,
          symbol,
        })
      );
    }

    helper.updateUriSymbol(TRADING_TYPE.spot, symbol);
  }, [symbol, defaultSymbol, availablePairs]);

  const [activeIndex, setActiveIndex] = useState(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  const time = new Date();
  time.setSeconds(time.getSeconds() + 86400);

  return (
    <div className={styles.exchange}>
      <Main />
      {closeDate && closeDate.getTime() < Date.now() && (
        <div className={styles.expiry_wrapper}>
          <div>
            <div>The mock trading period has successfully expired</div>
            <span>Read more</span>
          </div>
          <div>View Details</div>
        </div>
      )}
      {openDate && openDate.getTime() > Date.now() && (
        <div
          className={styles.countdown_wrapper}
          style={{
            position: "absolute",
            zIndex: 9,
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TradingCountDown expiryTimestamp={openDate} />
        </div>
      )}
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {isTablet ? (
        <>
          {(!openDate || openDate.getTime() < Date.now()) && (
            <SpotFormWrapper />
          )}
          {activeIndex === 0 && (
            <div className={styles.box}>
              <AdvancedChart />
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <Orderbook />
            </div>
          )}
          {activeIndex === 2 && (
            <div className={styles.box}>
              <RecentTrades />
            </div>
          )}
          <Table />
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.wrapper}>
              <AdvancedChart />
              <MarketActivity />
            </div>
            {(!openDate || openDate.getTime() < Date.now()) && (
              <div>
                <Table />
              </div>
            )}
          </div>
          <div className={styles.col}>
            <SpotFormWrapper />
          </div>
        </div>
      )}
    </div>
  );
};

export default Spot;
