import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import { supportedLangs } from "utils/defaults.js";
import i18n from "i18n.js";

const Page = ({ headerHide, children, footerHide, headerWide }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  useEffect(() => {
    // Extract lang query parameter
    const params = new URLSearchParams(search);
    const lang = params.get("lang");

    // Set i18n language if lang is present
    if (lang && i18n.language !== lang && supportedLangs.includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [search]);

  return (
    <>
      <div className={styles.page}>
        <div className={styles.inner}>{children}</div>
      </div>
    </>
  );
};

export default withRouter(Page);
